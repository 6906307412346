<template>
  <div>
    <!--二维码支付弹层-->
    <el-dialog class="wechatDialog" :close-on-click-modal="false" :visible.sync="dialogVisible"
               :before-close="handleClose" width="600px" center>
      <div class="mb-100"></div>
      <div class="wechatDialog-center" v-loading="showLoading">
        <!-- 显示 二维吗 -->
        <div class="wechatDialog-center-img" :id="divId" v-show="status == 9"></div>

        <!-- 微信加载失败 -->
        <div class="wechatDialog-center-img-error" v-show="status == 1">
          <div class="wechatDialog-center-img-error-center" style="cursor: pointer;" @click="pay">
            <i class="iconfont icon-reload24X24" style="color: #fff;"></i>
            <div class="mb-20"></div>
            <strong class="f-14">二维码加载失败点击刷新</strong>
          </div>
        </div>

        <!-- 二维码过期 -->
        <div class="wechatDialog-center-img-pas" v-show="status == 2">
          <img src="@/assets/weixin.png" alt=""/>
          <div class="wechatDialog-center-img-pas-shadow" style="cursor: pointer;" @click="pay">
            <i class="iconfont icon-reload24X24" style="color: #fff;"></i>
            <div class="mb-20"></div>
            <strong class="f-14">二维码已过期 点击刷新</strong>
          </div>
        </div>

        <!-- 支付宝跳转支付-->
        <div v-show="paymentWay == 'web'">
          <div ref="alipayWap" v-html="alipayHTML"/>
        </div>

        <!-- 线下汇款 -->
        <div class="wechatDialog-center-img-pas" style="width:700px" v-show="paymentWay == 'offline'">
          <div>
            <i class="iconfont icon-hour" style="font-size:100px"></i>
          </div>
          <div style="margin-top:20px;">
            <strong class=""
                    style="font-size:14px;line-height: 22px;">工作人员会在24小时内核实您的信息<br/>若有疑问请致电：010-64575166</strong>
          </div>
        </div>
        <!--支付成功-->
        <div class="wechatDialog-center-img-pas" v-show="status == 10 || paymentWay=='walletOnly'">
          <div>
            <i class="iconfont icon-success g" style="font-size:100px"></i>
          </div>
          <div style="margin-top:20px;">
            <strong class="g" style="font-size:28px;">支付成功</strong>
          </div>
        </div>
      </div>
      <div class="mb-40"></div>
      <p class="h-57 f-14" v-show="status != 10">{{ tip }}</p>
      <div class="mb-10"></div>
      <p class="h-57 f-14 r">{{ tip2 }}</p>
      <div class="mb-100"></div>
    </el-dialog>
    <!-- END 二维码支付弹层-->
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {toRecharge, queryRechargeStatus, toRechargeCoin, queryRechargeCoinStatus} from "@/api/user";

export default {
  name: "pay",
  props: {
    isCoin: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: '0'
    },
    paymentType: {
      type: Number,
      default: 1
    },
    paymentWay: {
      type: String,
      default: 'scan'
    },
    qrCodeStatus: {
      type: Number,
      default: 0
    },
    divId: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    payDialogTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryPaymentStatusTimer: null,
      queryPerMicSeconds: 1500,
      queryTimes: 0, //请求次数
      status: 0,
      payData: {
        payment: {
          payment_type: 'loading..'
        }
      },
      showLoading: false,
      tip2: '',
      alipayHTML: ''
    };
  },
  computed: {
    tip: function () {
      if (this.paymentType == 1) {
        return '请打开微信，使用微信扫一扫支付';
      } else if (this.paymentType == 2) {
        return this.paymentWay == 'scan' ? '请打开支付宝，扫码支付' : '正跳转到支付宝...';
      } else if (this.paymentType == 5) {
        return '';
      }
    }
  },
  beforeDestroy() {
    if (this.queryPaymentStatusTimer != null) {
      clearInterval(this.queryPaymentStatusTimer);
    }
  },
  created() {
    this.$nextTick(() => {
      this.pay();
    });
  },
  methods: {
    async pay() {
      this.showLoading = true;
      let res = {}
      if( this.isCoin ){
        res = await toRechargeCoin(
            {
              payment_type: this.paymentType,
              payment_way: this.paymentWay,
              count: this.amount
            },
        )
      }else{
        res = await toRecharge(
            {
              payment_type: this.paymentType,
              payment_way: this.paymentWay,
              amount: this.amount
            },
        );
      }
      if (res.statusCode == 200) {
        this.payData = res.data;
        if (res.data.payment.type == 'scan') {
          this.status = 9;
          this.generateQRCode();
        } else if (res.data.payment.type == 'web') {
          this.status = 0;
          this.alipayHTML = res.data.payment.body;
          // 防抖避免重复支付
          this.$nextTick(() => {
            // 提交支付表单
            this.$refs.alipayWap.children[0].submit();
            setTimeout(() => {
              // this.toPayFlag = false;
            }, 1000);
          });
        }
      } else {
        this.status = 1; //显示错误
        this.tip2 = res.message;
      }
      this.showLoading = false;
    },
    handleClose() {
      this.$emit('closeDialog');
    },
    // 生成二维码
    generateQRCode() {
      let doc = document.querySelector('#' + this.divId + ' img');
      let box = document.getElementById(this.divId);
      let qr = null;
      if (doc == null) {
        qr = new QRCode(box, {
          text: this.payData.payment.qr_code,
          width: 180,
          height: 180,
          colorDark: '#000000',
          colorLight: '#ffffff'
        });
      } else {
        doc.remove();
        qr = new QRCode(box, {
          text: this.payData.payment.qr_code,
          width: 180,
          height: 180,
          colorDark: '#000000',
          colorLight: '#ffffff'
        });
      }
      if (qr) {
        this.queryTimes = 0;
        this.startQueryPaymentTimer();
      }
    },
    startQueryPaymentTimer() {
      this.queryPaymentStatusTimer = setInterval(this.queryPaymentStatus, this.queryPerMicSeconds);
    },
    stopQueryPaymentTimer() {
      if (this.queryPaymentStatusTimer != null) {
        clearInterval(this.queryPaymentStatusTimer);
      }
    },
    queryPaymentStatus() {
      this.queryTimes++;
      let querySeconds = (this.queryTimes * this.queryPerMicSeconds) / 1000; //请求的秒数

      //请求超时
      if (querySeconds > this.payData.payment.expire_seconds) {
        this.status = 2;
        clearInterval(this.queryPaymentStatusTimer);
      }

      if ( this.isCoin ){
        let res = queryRechargeCoinStatus({sn: this.payData.sn}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.status = 10;
            clearInterval(this.queryPaymentStatusTimer);
            this.tip2 = "3秒后刷新页面...";
            let _this = this;
            setTimeout(function () {
              _this.$emit('paySuccess');
            }, 3000);
          }
        });
      }else{
        let res = queryRechargeStatus({sn: this.payData.sn}
        ).then((res) => {
          if (res.statusCode == 200) {
            this.status = 10;
            clearInterval(this.queryPaymentStatusTimer);
            this.tip2 = "3秒后刷新页面...";
            let _this = this;
            setTimeout(function () {
              _this.$emit('paySuccess');
            }, 3000);
          }
        });
      }
    }
  }
}
</script>

<style scoped lang="less">
.wechatDialog {
  .wechatDialog-center {
    width: 240px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .wechatDialog-center-img {
      width: 180px;
      height: 180px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .wechatDialog-center-img-error {
      background-color: #4c4c4c;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 180px;

      .wechatDialog-center-img-error-center {
        width: 100px;
        height: 110px;
        text-align: center;
      }

      i {
        font-size: 45px;
      }

      strong {
        color: #fff;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .wechatDialog-center-img-pas {
      width: 180px;
      height: 180px;
      text-align: center;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .wechatDialog-center-img-pas-shadow {
        position: absolute;
        top: 0px;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;

        i {
          position: relative;
          top: 33px;
          font-size: 45px;
        }

        strong {
          position: relative;
          top: 33px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  p {
    text-align: center;
    width: 300px;
    margin: 0 auto;
  }
}
</style>
