<template>
  <div class="zhuabi-background">

    <!-- 分为左右两部分 -->
    <div class="zhuabi">
      <!-- 切换部分 后期会变成一个树状菜单 -->
      <!-- 右边部分 -->
      <div v-if="indexBox" class="zhuabi-shop">
        <div class="zhuabi-right">
          <!-- 中间我的爪币部分 -->
          <div class="zhuabi-right-center">
            <!-- 爪币概括部分 -->
            <div class="zhuabi-right-center-generalize">
              <div class="zhuabi-right-center-generalize-top">
                <Isvg icon-class="coin"/>
                <span>爪币概念</span>
              </div>
              <div class="zhuabi-right-center-generalize-bottom">
                <div class="zhuabi-right-center-generalize-bottom1">
                  <li>
                    冻结中
                    <el-tooltip class="item" effect="dark" content="冻结中" placement="top-start">
                      <img src="@/icons/svg/shop/疑问提示16X16.svg" alt=""/>
                    </el-tooltip>
                  </li>
                  <p> {{ coinInfo.freeze_coin }} </p>
                </div>
                <div class="zhuabi-right-center-generalize-bottom2">
                  <li>
                    可用爪币
                    <el-tooltip class="item" effect="dark" content="可用爪币" placement="top-start">
                      <img src="@/icons/svg/shop/疑问提示16X16.svg" alt=""/>
                    </el-tooltip>
                  </li>
                  <p> {{ coinInfo.available_coin }} </p>
                </div>
                <div class="zhuabi-right-center-generalize-bottom3">
                  <li>累计支出
                    <el-tooltip class="item" effect="dark" content="累计支出" placement="top-start">
                      <img src="@/icons/svg/shop/疑问提示16X16.svg" alt=""/>
                    </el-tooltip>
                  </li>
                  <p> {{ coinInfo.out_coin }} </p>
                </div>
                <div class="zhuabi-right-center-generalize-bottom-button">
                  <button type="button" @click="showRecharge" >充值爪币</button>
                </div>
              </div>
            </div>
            <!-- 爪币攻略部分 -->
            <div class="zhuabi-right-center-strategy">
              <div class="zhuabi-right-center-strategy-top">
                <p>
                  <img
                      src="@/icons/svg/shop/爪币攻略16X16.svg"
                      alt=""
                  />爪币攻略
                </p>
              </div>
              <div class="zhuabi-right-center-strategy-bottom">
                <div class="zhuabi-right-center-strategy-bottom-img">
                  <div class="zhuabi-right-center-strategy-bottom-img-left">
                    <img src="@/assets/banner/获取更多爪币.jpg" alt=""/>
                  </div>
                  <div class="zhuabi-right-center-strategy-bottom-img-right">
                    <img src="@/assets/banner/爪币当钱花.jpg" alt=""/>
                  </div>
                </div>
                <!-- 图片下面的文字 -->
                <div class="zhuabi-right-center-strategy-bottom-font">
                  <div class="zhuabi-right-center-strategy-bottom-font1">
                    <div class="zhuabi-right-center-strategy-bottom-font1-top">
                      <ul>
                        <li>获取爪币的方法获取方法获取方法</li>
                      </ul>
                      <ol>
                        <li>
                          请通过App
                          Store下载官方下载官方正式版，任意国家和地区都支持购买和学习。
                        </li>
                        <li>
                          使用苹果应用内购买（IAP）支付，需要登录App
                          Store账号并绑定银联卡、支付宝或微信支付。仍然提示支付失败，可能是由于苹果服务器不稳定，请稍后再试或重启手机。
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="zhuabi-right-center-strategy-bottom-font2">
                    <div class="zhuabi-right-center-strategy-bottom-font1-top">
                      <ul>
                        <li>获取爪币的方法获取方法获取方法</li>
                      </ul>
                      <ol>
                        <li>
                          请通过App
                          Store下载官方下载官方正式版，任意国家和地区都支持购买和学习。
                        </li>
                        <li>
                          使用苹果应用内购买（IAP）支付，需要登录App
                          Store账号并绑定银联卡、支付宝或微信支付。
                        </li>
                        <li>
                          若满足以上条件，仍然提示支付失败，可能是由于苹果服务器不稳定，请稍后再试或重启手机。
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Recharge v-if="rechargeBox" :is-coin="true" @hideRechargeBox="hideRecharge" @PaySuccess="onPaySuccess" />
    </div>
  </div>
</template>

<script>
import {myCoin} from "@/api/coin";
import {Recharge} from "@/_components/Pages/Home/Account/Recharge";

export default {
  name: "coin",
  components: {
    Recharge
  },
  data() {
    return {
      coinInfo: {},
      indexBox: true,
      rechargeBox: false
    };
  },
  created() {
    this.loadCoinInfo();
  },
  methods: {
    async loadCoinInfo() {
      const res = await myCoin(localStorage.getItem("token"));
      if (res.statusCode == 200) {
        this.coinInfo = res.data;
      }
    },
    onPaySuccess() {
      console.log('recharge onPaySuccess callBack')
      this.loadCoinInfo()
      this.hideRecharge()
    },
    showRecharge() {
      this.indexBox = false
      this.rechargeBox = true
    },
    hideRecharge() {
      this.indexBox = true
      this.rechargeBox = false
    }
  }
};
</script>

<style lang="less" scoped>
.zhuabi-background {
  background-color: #f8f8f8;
}

.all-banner img {
  width: 100%;
  height: 224px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.zhuabi {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.zhuabi-shop {
  display: flex;
  flex-wrap: wrap;
}

.zhuabi-right {
  width: 940px;

  .zhuabi-right-top {
    margin-bottom: 10px;

    span {
      color: #575757;
      line-height: 36px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  /* 爪币部分的内容 */

  .zhuabi-right-center {
    width: 940px;
    min-height: 930px;
    background: #ffffff;
    /* 爪币概括部分 */

    .zhuabi-right-center-generalize {
      width: 739px;
      height: 203px;
      float: left;
      margin-top: 20px;
      margin-left: 40px;

      .zhuabi-right-center-generalize-top {
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        .flex;
        align-items: center;
        span {
          margin-left: 10px;
          color: #575757;
        }
      }

      .zhuabi-right-center-generalize-bottom {
        margin-left: 100px;
        margin-top: 40px;
        display: flex;
        text-align: center;
        flex-wrap: wrap;

        li {
          font-size: 16px;
          color: #595757;

          img {
            margin-left: 10px;
            width: 16px;
            height: 16px;
          }
        }

        p {
          margin-top: 22px;
          font-size: 40px;
          font-weight: 500;
          color: #575757;
        }

        .zhuabi-right-center-generalize-bottom2 {
          margin: 0 170px 0 183px;
        }

        .zhuabi-right-center-generalize-bottom-button {
          width: 200px;
          margin: 0 auto;
          margin-top: 26px;

          button {
            width: 200px;
            height: 36px;
            background: #e3007f;
            font-size: 18px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    /* 爪币攻略部分 */

    .zhuabi-right-center-strategy {
      margin-top: 40px;
      width: 900px;
      float: left;
      margin-left: 40px;

      .zhuabi-right-center-strategy-top {
        p {
          font-size: 18px;
          color: #474747;
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }

      .zhuabi-right-center-strategy-bottom {
        width: 900px;

        .zhuabi-right-center-strategy-bottom-img {
          margin-top: 20px;
          display: flex;
          margin-bottom: 20px;

          img {
            width: 420px;
            height: 120px;
          }

          .zhuabi-right-center-strategy-bottom-img-left {
            margin-right: 20px;
          }
        }

        .zhuabi-right-center-strategy-bottom-font {
          width: 900px;

          .zhuabi-right-center-strategy-bottom-font1 {
            .zhuabi-right-center-strategy-bottom-font1-top {
              ul {
                margin-bottom: 20px;

                li {
                  font-size: 18px;
                  list-style: disc;
                  list-style-position: inside;
                }
              }

              ol {
                width: 827px;
                margin-left: 20px;

                li {
                  list-style: decimal;
                  list-style-position: inside;
                  font-size: 14px;
                  color: #474747;
                }

                li:last-of-type {
                  line-height: 34px;
                  margin-bottom: none;
                  position: relative;
                  top: 10px;
                }
              }
            }
          }

          .zhuabi-right-center-strategy-bottom-font2 {
            margin-top: 40px;

            .zhuabi-right-center-strategy-bottom-font1-top {
              ul {
                margin-bottom: 20px;

                li {
                  font-size: 18px;
                  list-style: disc;
                  list-style-position: inside;
                }
              }

              ol {
                width: 827px;
                margin-left: 20px;

                li {
                  list-style: decimal;
                  list-style-position: inside;
                  font-size: 14px;
                  color: #474747;
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
