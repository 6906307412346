<template>
  <div class="go-top-up">
    <div class="go-top-up-header">
      <p>
        <strong>充值</strong>
        <img src="@/icons/svg/person/提示16X16.svg" alt=""/>
        <span>充值即代表您同意授权使用您的账户来支付这笔押金和未来的付款。</span>
        <el-link icon="el-icon-back" type="primary" @click="backLastPage">返回</el-link>
      </p>
    </div>
    <div class="rechargeMain">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-position="right" label-width="95px">
        <div class="rechargeamountBox">
          <el-form-item :label="isCoin?'充值爪币':'充值金额'" prop="amount">
            <el-input
                v-model="ruleForm.amount"
                type="number"
                :placeholder="isCoin?'请输入您要充值的爪币数量':'请输入您要充值的金额（单位，元）'" clearable/>
          </el-form-item>
<!--          <p class="r" v-if="!isCoin"> 费用说明：xxxxxxxxxxxxx </p>-->
          <p class="r" v-if="isCoin"> 1爪币=1.00元人民币    若扣款成功但爪币未到账，请联系客服 </p>
        </div>
        <div class="rechargeSelectBtn">
          <div @click="ruleForm.pay_type=payTypes.ali" :class="{active: ruleForm.pay_type==payTypes.ali}">
            <svg class="icon font-24" aria-hidden="true">
              <use xlink:href="#icon-alipay"/>
            </svg>
            <br/> 支付宝
          </div>
          <div @click="ruleForm.pay_type=payTypes.wechate" :class="{active: ruleForm.pay_type==payTypes.wechate}">
            <svg class="icon font-24" aria-hidden="true">
              <use xlink:href="#icon-a-WeChatPay"/>
            </svg>
            <br/>微信
          </div>
          <div @click="ruleForm.pay_type=payTypes.bank" :class="{active: ruleForm.pay_type==payTypes.bank}">
            <svg class="icon font-24" aria-hidden="true">
              <use xlink:href="#icon-UnionPay"/>
            </svg>
            <br/>线下付款
          </div>
        </div>
        <div class="rechargeBtn">
          <el-collapse-transition>
            <el-card v-if="ruleForm.pay_type==payTypes.bank">
              <p>平台收款账户信息</p>
              <div>
                户名：北京联众智赢科技有限公司 <br>
                账号：7510 0180 8097 7877 7 <br>
                开户行：中国光大银行股份有限公司北京新源支行
              </div>
            </el-card>
          </el-collapse-transition>
          <el-button
              type="primary"
              v-if="ruleForm.pay_type==payTypes.ali"
              @click="custody('web')">
            跳转到支付宝 ¥{{ ruleForm.amount }}
          </el-button>
          <el-button
              type="primary"
              v-if="ruleForm.pay_type==payTypes.ali"
              @click="custody('scan')">
            支付宝扫码 ¥{{ ruleForm.amount }}
          </el-button>
          <el-button
              type="primary"
              v-if="ruleForm.pay_type==payTypes.wechate"
              @click="custody('scan')">
            微信扫码 ¥{{ ruleForm.amount }}
          </el-button>
          <el-button
              type="primary"
              v-if="ruleForm.pay_type==payTypes.bank"
              @click="custody('offline')">
            我已线下汇款 ¥{{ ruleForm.amount }}
          </el-button>
          <p class="bankMessage r font-12" v-if="ruleForm.pay_type==payTypes.bank">
            银行卡支付周期为1-3个工作日，请耐心等待，避免重复支付
          </p>
        </div>
      </el-form>
    </div>
    <!--二维码支付弹层-->
    <Pay v-if="showQrcodeDialog"
         :div-id="qrcodeImgDivId"
         :qr-code-status="generateQrcodeStatus"
         :pay-dialog-title="payDialogTitle"
         :dialog-visible="showQrcodeDialog"
         :payment-type="paymentType"
         :payment-way="paymentWay"
         :amount="payAmount"
         :isCoin="isCoin"
         @closeDialog="closePayDialog"
         @paySuccess="onPaySuccess"/>
    <!-- END 二维码支付弹层-->
  </div>
</template>

<script>
import Pay from "@/_components/Pages/Home/Account/Recharge/Pay";

export default {
  name: "Recharge",
  components: {
    Pay
  },
  props: {
    isCoin: {
      type: Boolean,
      default: false
    },
    defaultMoney: {
      type: Number,
      default: 0
    }
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      if(!this.isCoin){
        if (value < 1 || value > 9999999) {
          callback(new Error('充值金额必须在 1~9999999 元之间!'));
        } else {
          callback();
        }
      }else{
        if (value < 1 || value > 100) {
          callback(new Error('充值爪币必须在 1~100 个之间!'));
        } else {
          callback();
        }
      }
    };
    return {
      qrcodeLoading: false, //显示二维吗时候的loading效果
      showQrcodeDialog: false, //是否先二维吗支付的dialog
      generateQrcodeStatus: 0, //0未生成 1生成失败 2失效  9成功
      payDialogTitle: '', //支付弹窗的标题
      payData: {},
      qrcodeImgDivId: 'qrcodeImg',
      queryPaymentStatusTimer: null,
      payButtonLoading: false, //支付按钮加载
      paymentWay: 'scan', //支付方式
      paymentType: 1,
      payAmount: 0,
      //充值表单
      ruleForm: {
        amount: "",  //充值金额
        pay_type: 0// 支付类型
      },
      rules: {
        amount: [
          {required: true, message: this.isCoin?'请输入充值的爪币个数！':'请输入充值金额！', trigger: 'blur'},
          {validator: validateAmount, trigger: 'blur' }
        ]
      },

      payTypes: {
        wechate: 1,
        ali: 2,
        bank: 3
      }

    }
  },
  computed: {
    theAmount() {
      const {amount} = this.ruleForm;
      return {
        amount
      };
    },
  },
  watch:{
    theAmount(newv){
      if(newv < 1 || newv > 9999999){
        this.ruleForm.amount = 9999999
      }
    }
  },
  mounted() {
    if( this.defaultMoney > 0 ){
      this.ruleForm.amount = this.defaultMoney
    }
  },
  methods: {
    onPaySuccess() {
      this.showQrcodeDialog = false;
      this.showPaySuccess = true;
      this.$emit('paySuccess');
    },
    closePayDialog() {
      this.showQrcodeDialog = false;
    },
    custody(paymentWay) {
      if( !this.isCoin && ( this.ruleForm.amount < 1 || this.ruleForm.amount > 9999999 ) ){
        this.$message.error('输入的充值金额不合规')
        return false;
      }else if( this.isCoin && ( this.ruleForm.amount < 1 || this.ruleForm.amount > 100 ) ){
        this.$message.error('输入的爪币数量不合规')
        return false;
      }else{
        this.paymentType = this.ruleForm.pay_type;
        this.paymentWay = paymentWay;
        this.payAmount = this.ruleForm.amount
        this.showQrcodeDialog = true;
      }
    },
    backLastPage() {
      this.$emit('hideRechargeBox')
    }
  }

}
</script>

<style lang="less" scoped>
.go-top-up {
  width: 940px;
  min-height: 930px;
  background: #ffffff;

  &-header {
    height: 79px;
    border-bottom: 1px solid #b5b5b5;

    p {
      height: 18px;
      width: 900px;
      position: relative;
      top: 40px;
      left: 40px;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-left: 20px;
        margin-right: 10px;
      }

      strong {
        font-size: 18px;
      }

      span {
        font-size: 12px;
        color: #575757;
        position: relative;
      }

      .el-link {
        float: right;
        margin-right: 20px;
      }
    }
  }

  .rechargeMain {
    width: 65%;
    margin: 0 auto;
    padding: 10%;

    .rechargeamountBox {
      border-bottom: 1px solid #b5b5b5;
      padding-bottom: 40px;
      margin-bottom: 40px;

      .el-input {
        width: 400px;
      }

      p {
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
      }
    }

    .rechargeSelectBtn {
      width: 100%;
      display: flex;
      justify-content: space-around;

      div {
        cursor: pointer;
        line-height: 24px;
        font-size: 14px;
        padding: 10px 0px;
        height: 50px;
        width: 100px;
        text-align: center;
      }

      .active {
        color: #00ace6;
      }
    }

    .rechargeBtn {
      text-align: center;
      margin: 40px auto;

      .el-button {
        background-color: #00ace6;
        width: 200px;
      }

      .el-card {
        margin-bottom: 20px;

        div {
          margin-top: 10px;
          line-height: 22px;
          font-size: 12px;
        }
      }

      .bankMessage {
        margin-top: 20px;
      }
    }
  }
}
</style>
